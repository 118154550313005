import { from, of } from 'rxjs';
import { combineEpics } from 'redux-observable';
import {
  mergeMap,
  tap,
  map,
  ignoreElements,
  catchError,
  first,
} from 'rxjs/operators';
import { httpRequest, ofType } from '@synthetica-ai/system';
import { localStorageValue, sessionStorageValue } from '@synthetica-ai/utils/storage';
import {
  USER,
  TOKEN,
  REFRESH_TOKEN,
} from '@synthetica-ai/constants';

import { openSnackbar } from 'models/ui';
import { redirectPromise, signInWithAzure } from 'services/azure';
import { emptyAction } from 'utils/epics';

import {
  login,
  updateUser,
  updateLoadingState,
  resetUserState,
  changePassword,
  logout,
  checkToken,
  validateAzureToken,
  loginWithAzure,
} from './actions';
import * as services from './services';

const token = localStorageValue(TOKEN);
const refreshToken = localStorageValue(REFRESH_TOKEN);
const user = localStorageValue(USER);

const redirectAfterAuthEpic = (action$) => action$.pipe(
  first(),
  mergeMap(() => from(redirectPromise()).pipe(
    mergeMap((response) => (
      response
        ? [validateAzureToken(response.accessToken)]
        : emptyAction())),
    catchError((err) => {
      console.log(err);
      return of(validateAzureToken.fail());
    }),
  )),
);

const validateAzureTokenEpic = (action$) => action$.pipe(
  ofType(validateAzureToken),
  httpRequest(action$, services.validateAzureToken),
);

const loginEpic = (action$) => action$.pipe(
  ofType(login),
  httpRequest(action$, services.loginService),
);

const loginWithAzureEpic = (action$) => action$.pipe(
  ofType(loginWithAzure),
  tap(() => signInWithAzure()),
  ignoreElements(),
);

const loginSuccessEpic = (action$) => action$.pipe(
  ofType(
    login.success,
    validateAzureToken.success,
  ),
  map(({ payload }) => ({
    ...payload,
    user: {
      ...payload.user,
      access: payload.user.access.split(','),
      fleet: payload.user?.fleet?.split(','),
      vessels: payload.user?.vessels?.split(','),
      drydock_ids: payload.user?.drydock_ids?.split(','),
    },
  })),
  tap((payload) => {
    const initialUrl = sessionStorageValue('initialUrl');
    const url = initialUrl.get();
    sessionStorage.clear();
    localStorage.clear();

    user.set(payload.user);
    token.set(payload.auth.token);
    refreshToken.set(payload.auth.refreshToken);

    if (url) {
      window.location.replace(`${window.location.protocol}//${window.location.host}${url}`);
      return;
    }

    if (payload.user.redirect_to) {
      window.location.replace(`${window.location.protocol}//${window.location.host}/${payload.user.redirect_to}`);
    }
  }),
  mergeMap((payload) => [
    updateLoadingState(false),
    updateUser(payload.user),
    openSnackbar({
      message: 'Successful login',
      type: 'success',
    }),
  ]),
);

const logoutEpic = (action$) => action$.pipe(
  ofType(logout),
  httpRequest(action$, services.logoutService),
);

const logoutSuccessEpic = (action$) => action$.pipe(
  ofType(logout.success),
  tap(() => localStorage.clear()),
  mergeMap(() => [
    resetUserState(),
  ]),
);

const changePasswordEpic = (action$) => action$.pipe(
  ofType(changePassword),
  httpRequest(action$, services.changePasswordService, {
    additionalSuccessActions: [
      () => updateLoadingState(false),
    ],
    additionalFailureActions: [() => updateLoadingState(false)],
  }),
);

const changePasswordSuccessEpic = (action$) => action$.pipe(
  ofType(changePassword.success),
  map(() => openSnackbar({
    message: 'Password changed successfully',
    type: 'success',
  })),
);

const checkTokenEpic = (action$) => action$.pipe(
  ofType(checkToken),
  httpRequest(action$, services.checkTokenService),
);

const changeTokenSuccessEpic = (action$) => action$.pipe(
  ofType(checkToken.success),
  tap(({ payload }) => token.set(payload.token)),
  map(() => updateUser(user.get())),
);

const changeTokenFailEpic = (action$) => action$.pipe(
  ofType(checkToken.fail),
  tap(() => localStorage.clear()),
  ignoreElements(),
);

const handleFailedRequestsEpic = (action$) => action$.pipe(
  ofType(
    login.fail,
    logout.fail,
    changePassword.fail,
  ),
  map(({ payload }) => openSnackbar({
    message: payload.error || 'Something went wrong',
    type: 'error',
  })),
);

export default combineEpics(
  loginEpic,
  logoutEpic,
  loginSuccessEpic,
  changePasswordEpic,
  changePasswordSuccessEpic,
  logoutSuccessEpic,
  checkTokenEpic,
  changeTokenSuccessEpic,
  changeTokenFailEpic,
  handleFailedRequestsEpic,
  loginWithAzureEpic,
  redirectAfterAuthEpic,
  validateAzureTokenEpic,
);
